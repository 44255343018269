/*@font-face {
    font-family: "Libre Bodoni";
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    src: url("/content/themes/paramount/fonts/libre-bodoni-v5-latin-regular.woff2") format("woff2"), url("/content/themes/paramount/fonts/libre-bodoni-v5-latin-regular.woff") format("woff");
}
*/
@font-face {
  font-display: fallback;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/paramount/fonts/montserrat-v25-latin-regular.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-regular.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("/content/themes/paramount/fonts/montserrat-v25-latin-700.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-700.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("/content/themes/paramount/fonts/montserrat-v25-latin-100.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-100.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("/content/themes/paramount/fonts/montserrat-v25-latin-600.woff2") format("woff2"), url("/content/themes/paramount/fonts/montserrat-v25-latin-600.woff") format("woff");
}

.button {
  color: #fff;
  background-color: #000;
}

.button:hover {
  background-color: #000;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--quickview {
  background: #000;
}

.button--pending::after {
  background-color: #000;
}

.site-nav {
  color: #000;
  background-color: #ffffff;
}

.site-nav__top {
  text-transform: capitalize;
  color: #000000;
}

.site-nav__sub.sub-nav {
  background: #fff;
  border-radius: 0px;
  border-top: 3px solid #BF5F5C;
  font-size: 14px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
}

.site-nav__item:hover .site-nav__top {
  background-color: #fff;
  color: #BF5F5C;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    margin-top: 0;
    display: flex;
    justify-content: center;
    background: none;
    max-width: none;
  }
  .site-nav__top {
    font-weight: 600;
    padding: 16px;
    font-size: 18px;
    border-radius: 0;
  }
}

.site-nav__item--promotions a {
  color: #238BA3;
}

.site-nav__item--promotions a:hover {
  color: #BF5F5C;
}

.site-nav__item--promotions .sub-nav__col a {
  color: #000;
}

.site-nav__item--holiday a {
  color: #AA0C0C;
}

.site-nav__item--holiday a:hover {
  color: #BF5F5C;
}

.menu-wrap .search {
  display: none;
}

.nav-toggles,
.nav-toggle {
  background-color: #fff;
  justify-self: center;
}

.burger {
  width: 30px;
  height: 23px;
}

.burger__middle {
  background: #000;
  top: 10px;
}

.burger::before,
.burger::after {
  background: #000;
}

@media only screen and (max-width: 620px) {
  .menu-wrap {
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 10px 0;
  }
  .menu-wrap .search {
    display: flex;
  }
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background-color: #fff;
  border: 2px solid #525252;
  color: rgba(0, 0, 0, 0);
}

.bx-wrapper .bx-pager.bx-default-pager a:hover, .bx-wrapper .bx-pager.bx-default-pager a.active {
  background-color: #525252;
}

.bx-wrapper .bx-next,
.bx-wrapper .bx-prev {
  background: url("/content/themes/paramount/images/universal-nav-arrows.png");
}

.bx-wrapper .bx-next {
  background-position: -36px 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -36px 0;
}

.bx-wrapper .bx-controls-direction a {
  width: 36px;
  height: 37px;
  opacity: 0.8;
  z-index: 900;
}

.main-banner-wrap .bx-wrapper .bx-next {
  right: 5px;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  left: 5px;
}

.secondary-banner-wrap {
  max-width: 1000px;
  margin: 70px auto 0;
}

@media only screen and (max-width: 680px) {
  .secondary-banner-wrap .main-banner-wrap {
    margin-top: 20px;
  }
}

.slider--products .slider-caption {
  font-size: 16px;
  font-weight: 600;
}

.slider--products .bx-wrapper .bx-pager {
  bottom: 0px;
}

.scroller-title {
  overflow: hidden;
}

.slider--featured .bx-viewport .slidewrap a:after {
  color: #fff;
  background-color: #000;
}

.bar {
  background-color: #702129;
}

.bar a {
  color: #fff;
}

.home-wrap .bar,
.catalogs__header {
  margin-top: 35px;
  margin-bottom: 20px;
  padding-top: 0;
  padding-bottom: 0;
  color: #000;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background: none;
  overflow: visible;
}

.home-wrap .bar--instagram, .standard-banners--tertiary .bar {
  position: relative;
}

.home-wrap .bar--instagram span, .standard-banners--tertiary .bar span {
  display: inline-block;
  position: relative;
  z-index: 200;
  padding: 0 10px;
  background: #fff;
}

.home-wrap .bar--instagram:after, .standard-banners--tertiary .bar:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 5px;
  background: #b35c52;
  transform: translateY(-50%);
}

.top-header {
  background-color: #000;
}

.top-header__inner {
  display: grid;
}

.header-top__countdown {
  grid-row: 1;
  width: 100%;
  color: #fff;
  width: 100%;
  text-align: right;
  max-width: 1200px;
  padding-right: 8px;
  margin: 0 auto;
}

.header-top__note {
  display: none;
}

.header-top__note--mobile {
  grid-row: 1;
  padding: 5px;
  text-align: center;
}

.header-top__assistance {
  grid-column: 1;
  padding: 0;
  max-width: 235px;
  line-height: 1.4;
  text-transform: none;
}

.header-top__assistance--mobile {
  display: none;
}

.header-top__assistance,
.header-top__assistance--mobile {
  color: #fff;
  background-color: #000;
}

.header-top__assistance a,
.header-top__assistance--mobile a {
  color: #fff;
  text-decoration: underline;
}

.site-header .google-translate {
  max-width: 1200px;
  padding-left: 10px;
}

.utility-bar {
  grid-row: 2;
}

.utility-bar__inner {
  grid-template-columns: 1fr auto auto;
  align-items: center;
  max-width: 1200px;
  padding: 0 12px;
}

.is--logged-out .utility-bar__inner {
  padding: 12px;
}

.utility-bar__inner a {
  color: white;
}

.login-links {
  grid-column: 2;
  text-transform: uppercase;
  color: #fff;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.account-header {
  grid-column: 2;
}

.header-cart {
  grid-column: 3;
}

.login-links,
.header-cart {
  margin-right: 8px;
}

.header-search {
  grid-column: 4;
}

.utility-bar .header-search {
  width: auto;
  max-width: 250px;
  justify-self: baseline;
  margin-left: 5px;
}

.search__options {
  background: #393738;
}

.search__options select {
  color: #fff;
}

.search__options select option {
  background: #393738;
}

.account-header__icon {
  display: block;
  justify-self: right;
}

.account-header__toggle {
  display: block;
  justify-self: left;
}

.shop-location {
  padding-left: 8px;
  padding-bottom: 8px;
  grid-row: 3;
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

.header-top-sites-container {
  grid-row: 4;
  background-color: #f1f1f1;
}

.header-top-flex-items {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  font-size: 12px;
}

.header-top-flex-items .parent-site {
  background-color: #fff;
}

.header-top-flex-items .beautyconnection-site {
  background: #ea50b2;
}

.header-top-flex-items .beautyconnection-site a {
  color: #fff;
}

.header-top-site-item {
  padding: 8px 20px;
  cursor: pointer;
  height: 100%;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.header-top-site-item a {
  color: #000;
}

.header-site-link__sub {
  font-style: italic;
  font-size: 12px;
  font-weight: normal;
  margin: 0px;
  margin-top: -5px;
  letter-spacing: 0;
}

.header-top-site__text {
  display: none;
}

.site-logo {
  padding: 0;
  margin: 0 auto;
}

.site-logo--mobile {
  display: none;
}

@media only screen and (min-width: 820px) {
  .header-top-flex-items {
    display: grid;
    grid-template-columns: 205px 135px 1fr;
    font-size: 16px;
  }
  .header-top-site__text {
    display: inline;
    font-size: 16px;
    font-style: italic;
    grid-row: 1;
    grid-column: 3;
    text-align: center;
    padding-right: 0px;
  }
  .site-header .google-translate {
    padding-top: 110px;
  }
  .is--logged-in .site-header .google-translate {
    padding-top: 110px;
  }
  .title-wrap {
    padding-top: 0em;
  }
  .utility-bar .header-search {
    max-width: 300px;
  }
}

@media only screen and (min-width: 1000px) {
  .header-top-site__text {
    text-align: right;
    padding-right: 150px;
  }
}

@media (max-width: 768px) {
  .header-top-site-item {
    flex: 1 1 50%;
    text-align: center;
    margin: 0;
  }
}

@media only screen and (max-width: 620px) {
  .top-header {
    background-color: unset;
  }
  .header-top__note--mobile {
    width: 100%;
    grid-column: 1 / span 2;
  }
  .locator-feature {
    grid-row: 2;
  }
  .header-top__assistance {
    display: none;
    grid-column: unset;
  }
  .header-top__assistance--mobile {
    display: block;
    width: 100%;
    text-align: center;
    padding: 10px;
    grid-column: 1 / span 2;
  }
  .shop-location {
    margin: 0 auto;
    font-size: 12px;
    grid-column: 1 / span 2;
    text-align: center;
    padding: 0 10px 10px;
    font-size: 12px;
    background-color: #000;
  }
  .shop-location__icon {
    display: none;
  }
  .header-top-sites-container {
    grid-row: 4;
    grid-column: 1 / span 2;
  }
  .header-top__countdown {
    grid-row: 5;
    grid-column: 1 / span 2;
    text-align: center;
    color: unset;
    padding-bottom: 5px;
  }
  .site-logo {
    display: none;
  }
  .site-logo--mobile {
    grid-row: 6;
    padding: 10px;
    margin: 0 auto;
    display: block;
    grid-column: 1 / span 2;
  }
  .site-logo--mobile .site-logo__img {
    margin: 0 auto;
    width: 80%;
  }
  .utility-bar {
    grid-row: 7;
    grid-column: 1 / span 2;
    background-color: #fff;
    padding: 0px;
  }
  .utility-bar__inner {
    grid-template-columns: 80% 20%;
    justify-items: center;
    padding: 0;
  }
  .utility-bar__inner a {
    color: #000;
  }
  .account-header__nav a {
    color: #fff;
  }
  .account-header {
    width: 100%;
    grid-column: 1;
    color: #000;
    background-color: #fff;
  }
  .header-cart {
    grid-column: 2;
    justify-self: end;
  }
  .account-header__account-greeting {
    display: none;
  }
  .account-header__header-i-guess {
    display: grid;
    grid-template-columns: auto auto 75%;
    align-items: center;
  }
  .account-header__icon {
    grid-column: 1;
    grid-row: 1;
  }
  .account-header__icon .icon-person .icon-circle {
    stroke: #000;
    stroke-width: 1;
  }
  .account-header__icon .icon-person .icon-body,
  .account-header__icon .icon-person .icon-head,
  .account-header__icon .account-header__toggle {
    fill: #000;
  }
  .account-header__toggle {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px 0 5px;
  }
  .account-header__title {
    grid-column: 3;
    text-align: left;
  }
  .header-cart__icon svg,
  .account-header__toggle svg {
    fill: #000;
  }
  .header-cart__value {
    color: #fff;
  }
  .search {
    display: none;
  }
  .title-wrap,
  .is--logged-in .title-wrap,
  .is--logged-in .title-wrap--store,
  .header-banner {
    padding: 0px;
  }
  .login-links {
    grid-column: 1;
    justify-self: start;
    padding-left: 20px;
    color: #000;
  }
  .header-search {
    padding-right: 10px;
    width: 100%;
    grid-column: 2;
  }
  .search,
  .search__text {
    max-width: unset;
  }
  .search__field {
    padding: 10px;
  }
  .search__submit {
    flex: 0 0 20px;
  }
  .button--search {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
  .search__options {
    display: none;
  }
}

@media only screen and (max-width: 400px) {
  .shop-location {
    font-size: 11px;
  }
}

/* --- Redesign - home page About Us ---*/
.page--default .about-us__wrapper {
  margin: 20px auto;
  background-color: #BFCDD0;
}

.page--default .about-us__inner {
  max-width: 1000px;
  margin: auto;
}

.page--default .about-us {
  /*width: 70%;*/
  float: right;
  vertical-align: middle;
  margin: 40px auto;
  padding: 30px 40px 40px;
  background: #fff;
  border-radius: 10px;
  min-height: 500px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
}

.page--default .about-us h2 {
  font-size: 40px;
  margin: 0.5em 0 0.5em;
}

.page--default .about-us a {
  margin-top: 10px;
}

.page--default .about-us__title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}

.page--default .about-us__text {
  font-size: 16px;
  line-height: 30px;
}

.page--default .about-us-menu {
  padding: 15px 0;
}

.page--default .about-us-menu__image {
  margin-left: -110px;
  width: 363px;
}

.page--default .about-us-items {
  top: 25%;
  left: 35%;
  position: absolute;
}

.page--default .about-us-item {
  margin: 25px 0px;
  display: flex;
  align-items: center;
  padding-top: 8px;
}

.page--default .about-us-item a {
  display: inline-flex;
}

.page--default .about-us-item a:hover {
  color: #000;
}

.page--default .about-us-item p {
  display: inline-block;
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin: 0 0 0 10px;
  padding-top: 8px;
}

.page--default .about-us-item img {
  border-radius: 50%;
  background: #fff;
  padding: 8px;
  display: inline-block;
}

@media only screen and (max-width: 999.98px) {
  .page--default .about-us {
    width: 100%;
  }
}

@media only screen and (max-width: 819.98px) {
  .page--default .about-us-items {
    left: 20%;
  }
}

@media only screen and (max-width: 680px) {
  .page--default .about-us-menu__image {
    margin-left: -35px;
    max-width: 65%;
  }
  .page--default .about-us-items {
    left: 50%;
  }
  .page--default .about-us-item {
    margin: 15px 0px;
    padding-top: 6px;
  }
  .page--default .about-us-item p {
    font-size: 16px;
  }
  .page--default .about-us-item img {
    padding: 6px;
  }
  .page--default .about-us {
    float: none;
    padding: 20px;
    width: 90%;
    min-height: inherit;
    margin-top: 10px;
  }
  .page--default .about-us h2 {
    font-size: 26px;
    margin-top: 20px;
  }
  .page--default .about-us__title {
    font-size: 14px;
  }
  .page--default .about-us__text {
    font-size: 12px;
    line-height: 20px;
  }
}

.catalogs {
  max-width: 1000px;
  margin: 30px auto 60px;
  border-top: none;
  display: block;
}

.catalogs a {
  color: #238BA3;
}

.catalogs__header {
  border-bottom: none;
}

.catalog,
.catalog:last-child {
  border-bottom: none;
}

.catalog__text::after {
  content: none;
}

.home-articles {
  clear: both;
}

.home-articles__item {
  width: 50%;
}

.home-articles__item:nth-child(3n+1) {
  clear: none;
}

.home-article__title {
  margin-top: -50px;
  margin-right: 50px;
  margin-left: 50px;
  padding: 25px;
  color: #000;
  font-size: 42px;
  text-align: center;
  text-transform: none;
  background: #fff;
}

/*.home-article__title {
	font-style: normal;
	font-weight: 100;
	letter-spacing: 1px;
}*/
.home-article__teaser {
  display: none;
}

.home-articles__more {
  display: none;
}

@media only screen and (max-width: 680px) {
  .home-articles__item {
    float: none;
    width: 100%;
  }
  .home-article__title {
    margin-top: -25px;
    margin-right: 25px;
    margin-left: 25px;
    padding: 10px;
    font-size: 18px;
  }
}

.post__more {
  position: static;
}

.site-footer {
  background-color: #000;
  max-width: none;
  padding: 0;
}

.site-footer .google-translate {
  max-width: 1000px;
}

.klaviyo-form-TsEfKP {
  background-color: #BFCDD0;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
}

.site-footer__header {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  border-bottom: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-top: 20px;
}

.site-footer__item {
  font-size: 14px;
  line-height: 28px;
  padding-top: 0px;
}

.site-footer a {
  color: #fff;
}

@media only screen and (max-width: 680px) {
  .site-footer__panel {
    padding-left: 40px;
  }
}

.i--bangstyle,
.i--email,
.i--facebook,
.i--feed,
.i--instagram,
.i--linkedin,
.i--pinterest,
.i--twitter,
.i--wordpress,
.i--youtube,
.i--tiktok {
  background: transparent url("/content/themes/paramount/images/social-icons.png") no-repeat;
  background-color: transparent;
}

.i--facebook {
  background-position: 0 0;
}

.i--instagram {
  background-position: -25px 0;
}

.i--pinterest {
  background-position: -50px 0;
}

.i--tiktok {
  background-position: -75px 0;
}

.i--email {
  background-position: -100px 0;
}

.i--youtube {
  background-position: -125px 0;
}

/*.i--linkedin {
  background-position: -542px 0;
}*/
.i--twitter-hover {
  background-color: #3dc7f4;
}

.i--facebook-hover {
  background-color: #3b5998;
}

.i--linkedin-hover {
  background-color: #00659b;
}

.i--pinterest-hover {
  background-color: #dc3e47;
}

.i--youtube-hover {
  background-color: #e40202;
}

.i--wordpress-hover {
  background-color: #247ca4;
}

.i--feed-hover {
  background-color: #e08e00;
}

.i--instagram-hover {
  background-color: #773eb1;
}

.i--bangstyle-hover {
  background-color: #9497ca;
}

.i--email-hover {
  background-color: #333333;
}

.footer-social {
  color: #fff;
  border-bottom: 1px solid #fff;
  padding-top: 50px;
}

.footer-social .social {
  margin-top: 20px;
}

@media only screen and (min-width: 820px) {
  .footer-social {
    width: 1000px;
    margin: 0 auto;
  }
}

.footer-social__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  column-gap: 10px;
}

.footer-social__icons img {
  width: 93px;
}

.footer-contact {
  margin-bottom: 10px;
}

.footer-contact img {
  margin-right: 5px;
}

.footer-contact > div {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.footer-contact > div:first-of-type {
  justify-content: center;
}

@media only screen and (max-width: 680px) {
  .footer-contact > div {
    justify-content: center;
  }
}

.site-footer__copyright {
  margin-top: 0px;
  padding: 15px 0 12px;
  color: #fff;
  font-size: 10px;
}

.popover__content .txt-center a {
  color: #702129;
}

.popover__coupon-text {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 300;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 0 15px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.popover__coupon-text::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  transition: background 0.8s;
}

.popover__coupon-text--visible::after {
  background: rgba(255, 255, 255, 0);
  pointer-events: none;
}

.popover--dark .popover__background {
  background: black;
  transition: background 0.6s;
}

.popover__close {
  z-index: 500;
}

.popover iframe {
  z-index: 400;
}

.videos {
  padding-top: 45px;
}

.video-thumb {
  position: relative;
}

.video-thumb__teaser {
  color: #333;
}

.video-thumb__image {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(90%);
  transition: filter 0.35s ease-in-out;
}

.video-categories {
  padding-top: 45px;
  text-align: center;
}

.video-category__link {
  padding: 10px 20px;
  color: #414042;
  font-size: 16px;
  text-decoration: none;
  text-transform: lowercase;
  border: 1px solid #414042;
}

.video-category__link--active {
  color: #fff;
  background: #414042;
}

.video-category__link:hover {
  text-decoration: none;
}

.video-thumb__link {
  color: #000;
}

.video-thumb__link:hover {
  text-decoration: none;
}

.video-thumb__link:hover .video-thumb__title {
  text-decoration: underline;
}

.video-thumb__link:hover .video-thumb__image, .video-thumb--active .video-thumb__image {
  filter: grayscale(0);
}

.video-thumb__title {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0;
  font-size: 1.35em;
}

@media only screen and (min-width: 800px) {
  .videos__thumbs {
    margin: 0 -15px;
    padding: 30px 0;
    overflow: hidden;
  }
  .video-thumb {
    float: left;
    width: 33.3333333%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
  .video-thumb:nth-child(3n+1) {
    clear: both;
  }
  .video-thumb__teaser {
    right: 15px;
    left: 15px;
  }
}

.login {
  color: #000;
}

.login-form__row--actions {
  display: block;
}

.login-form__forgot {
  font-size: 11px;
}

.login-section__actions {
  margin-top: 20px;
}

.login-section__header {
  font-size: 1.8em;
}

.login-section .button {
  padding: 8px 24px;
  /*transition: background-color 0.3s ease-in-out;

		&:hover {
			background-color: darken(#3a6c93, 10%);
		}*/
}

/*.login-section .button--text {
	background-color: transparent;
}*/
.cart__row[data-apiqty="0"] .cart__cell {
  background-color: rgba(128, 38, 40, 0.5);
  color: #fff;
}

.cart__row[data-apiqty="0"] .cart__cell .link-list,
.cart__row[data-apiqty="0"] .cart__cell .price__label--salon {
  color: #fff;
}

.sample-featured {
  background-color: #E5EBEC;
}

.price__label--sale,
.price__label--savings {
  color: #238BA3;
}

.price__label--highlight {
  color: #fff;
  background-color: #1e7a8f;
}

.prod-option:hover,
.related-prod:hover {
  border-color: #238BA3;
}

.filters__header,
.product-bar {
  background-color: #BFCDD0;
}

.pagination__link--active,
.pagination__link:hover {
  color: #fff;
  background-color: #000;
}

.deal-sku__link {
  color: #238BA3;
}

.myaccount-nav__link {
  background-color: #BFCDD0;
}

.myaccount-nav__link--selected, .myaccount-nav__link:hover {
  background-color: #238BA3;
}

.pbt-container {
  margin-bottom: 50px;
}

.pbt-container .sub-text {
  font-size: 12px;
  line-height: 15px;
}

.pbt-containerMg {
  margin-bottom: 65px;
}

.pbt-mainbanner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.brand-story-container {
  position: relative;
}

.pbt-btn {
  text-align: center;
}

.pbt-top-btn {
  text-align: left;
}

.pbtBtn-inner {
  color: #fff;
  background-color: #be7c5a;
  border-radius: 8px;
  font-size: 1.2em;
}

.pbt-titleTextCon .pbt-titleCon {
  padding: 20px 0px;
}

.pbt-titleTextCon .pbt-titleCon .pbt-titleInner {
  font-weight: 700;
}

.pbt-titleTextCon .pbt-text .pbt-textInner {
  line-height: 30px;
}

.pbt-videoImg-container .videoImag-container {
  text-align: center;
}

.pbt-videoImg-container .videoImag-container .videoImag-hc {
  font-weight: 700;
  margin-bottom: 40px;
}

.pbt-videoImg-container .pbt-vidImg-block {
  background-color: #ccc;
}

.pbt-imagetext-container {
  margin-bottom: 100px;
}

.pbt-imagetext-container .pbt-sbheader {
  font-weight: 700;
}

.pbt-imagetext-container .pbt-sbtext {
  line-height: 30px;
}

.pbt-imagetext-container .pbt-imgouter-left {
  position: relative;
  top: -35px;
  left: -35px;
  z-index: 110;
}

.pbt-imagetext-container .pbt-imgouter-right {
  position: relative;
  top: -35px;
  right: -35px;
  z-index: 110;
}

.pbt-text-container {
  margin-bottom: 65px;
}

.pbt-text-container .pbt-videoImag-container {
  text-align: center;
}

.pbt-text-container .pbt-videoImag-container .pbt-videoImag-hc {
  font-weight: 700;
  margin-bottom: 40px;
}

.pbt-text-container .pbt-textblock-container .pbt-textblock {
  text-align: center;
  line-height: 30px;
}

.pbt-twoimages-container {
  margin-bottom: 65px;
}

.pbt-twoimages-container .pbt-twoimages-headerCon {
  text-align: center;
}

.pbt-twoimages-container .pbt-twoimages-headerCon .pbt-twoimages-header {
  font-weight: 700;
  margin-bottom: 40px;
}

.pbt-threeimages-container .pbt-threeimages-headerCon {
  text-align: center;
}

.pbt-threeimages-container .pbt-threeimages-headerCon .pbt-threeimages-header {
  font-weight: 700;
  margin-bottom: 40px;
  margin-top: 50px;
}

.pbt-imgSBanCon {
  margin-bottom: 65px;
}

@media (min-width: 768px) {
  .pbt-logoCon.left {
    position: absolute;
    top: 150px;
    left: 100px;
    z-index: 200;
  }
  .pbt-logoCon.right {
    position: absolute;
    top: 150px;
    right: 100px;
    z-index: 200;
  }
  .pbtLogo {
    font-size: 4em !important;
    font-weight: 700;
    color: #4c4c4c;
  }
  .pbtBtn-inner {
    padding: 15px 40px;
  }
  .pbt-titleTextCon {
    padding: 0px 0px 0px 40px;
  }
  .pbt-imagetext-container {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .pbt-imagetext-container .ht-container-right {
    padding-right: 100px;
  }
  .pbt-imagetext-container .ht-container-left {
    padding-left: 100px;
  }
  .pbt-imagetext-container .pbt-imgouter-left {
    position: relative;
    top: -35px;
    left: -35px;
    z-index: 110;
  }
  .pbt-imagetext-container .pbt-imgouter-right {
    position: relative;
    top: -35px;
    right: -35px;
    z-index: 110;
  }
  .pbt-imagetext-container .pbt-imgBx::after {
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    left: 15px;
    z-index: 100;
    border: 3px solid #be7c5a;
  }
}

@media (max-width: 767.98px) {
  .pbt-logoCon.left {
    position: absolute;
    top: 65px;
    left: 35px;
    z-index: 200;
  }
  .pbt-logoCon.right {
    position: absolute;
    top: 65px;
    right: 35px;
    z-index: 200;
  }
  .pbtLogo {
    font-size: 2em !important;
    font-weight: 700;
    color: #4c4c4c;
  }
  .pbtBtn-inner {
    padding: 5px 15px;
  }
  .pbt-imagetext-container {
    margin-bottom: 100px;
  }
  .pbt-imagetext-container .ht-container-right {
    padding-right: 0px;
  }
  .pbt-imagetext-container .ht-container-left {
    padding-left: 0px;
  }
  .pbt-imagetext-container .pbt-imgouter-left {
    position: relative;
    top: -15px;
    left: -5px;
    z-index: 110;
  }
  .pbt-imagetext-container .pbt-imgouter-right {
    position: relative;
    top: -15px;
    right: 5px;
    z-index: 110;
  }
  .pbt-imagetext-container .pbt-imgBx::after {
    content: "";
    position: absolute;
    top: 0;
    right: 3px;
    bottom: 0;
    left: 15px;
    z-index: 100;
    border: 3px solid #be7c5a;
  }
}

@media (max-width: 575.98px) {
  .brand-story-summary__image {
    margin-bottom: 50px;
  }
  .pbt-grid {
    display: grid;
  }
  .pbt-inner-grid {
    grid-row: 1/1;
  }
  .pbtmobile-margin {
    margin-top: 50px;
  }
}

.brand-story-article {
  text-align: center;
}

.landing .brand-story-article__image {
  display: inline-block;
}

@media only screen and (min-width: 680.02px) {
  .brand-story-articles {
    display: flex;
    margin-bottom: 60px;
  }
}

/*  --- all landing pages ---- */
.page--landing-default .breadcrumb {
  display: none;
}

/* ---- about us landing page ---- */
.landing .landing-about-us h1 {
  margin-top: 10px;
  font-size: 25px;
}

.landing .landing-about-us p {
  font-size: 14px;
  line-height: 30px;
}

.landing .landing-about-us h2 {
  font-size: 22px;
  font-weight: 700;
}

.landing-about-us h1 {
  text-align: center;
  font-weight: 600;
}

@media only screen and (min-width: 480.02px) {
  .landing .landing-about-us h1 {
    margin-top: 50px;
    font-size: 33px;
  }
  .landing .landing-about-us p.main-text {
    font-size: 20px;
  }
}

@media only screen and (min-width: 820px) {
  .landing .landing-about-us p {
    font-size: 17px;
    line-height: 32px;
  }
  .landing .landing-about-us p.main-text {
    font-size: 24px;
    line-height: 40px;
  }
  .landing .landing-about-us h2 {
    font-size: 28px;
  }
}

.landing-about-us .main-text {
  text-align: center;
  margin: auto;
  max-width: 800px;
}

.landing-about-us .responsive-video {
  margin-bottom: 50px;
}

.landing-about-us .about-us-border {
  border: 3px solid #000;
  height: 95%;
  width: 94%;
  top: 5%;
  left: 3%;
  position: absolute;
  z-index: 10;
}

.landing-about-us .about-us-border-video {
  border: 3px solid #000;
  height: 100%;
  width: 100%;
  position: absolute;
}

.landing-about-us .about-us-section {
  margin-top: 60px;
}

.landing-about-us .about-us-section:last-of-type {
  margin-bottom: 30px;
}

.landing-about-us .about-us-section--small {
  margin: 60px 35px 0 35px;
}

.landing-about-us .about-us-section--small .g-6 {
  padding: 0px;
}

.landing-about-us .about-us-section--small .small-row__right {
  background: #BFCDD0;
}

.landing-about-us .about-us-section--small .small-row__right p {
  font-size: 13px;
  line-height: 24px;
}

.landing-about-us .inner-box__text {
  padding: 0px 20px 20px;
}

.landing-about-us .inner-box__text p {
  position: relative;
  z-index: 20;
}

@media only screen and (min-width: 680.02px) {
  .landing-about-us .about-us-section--small {
    margin-left: 65px;
    margin-right: 65px;
    display: flex;
  }
  .landing-about-us .about-us-section--small .g-6 {
    margin: 0 5px;
  }
  .landing-about-us .about-us-section--small .small-row__inner {
    padding: 0px 20px;
    top: 8%;
  }
  .landing-about-us .about-us-section--small .small-row__right p {
    font-size: 12px;
    line-height: 16px;
  }
  .landing-about-us .about-us-border {
    height: 90%;
  }
  .landing-about-us .inner-box__text {
    padding: 40px 20px 20px;
  }
  .landing-about-us .small-row__inner {
    padding: 0 20px;
    position: absolute;
    top: 10%;
  }
}

@media only screen and (min-width: 680.02px) and (max-width: 900px) {
  .landing .landing-about-us p {
    font-size: 14px;
    line-height: 22px;
  }
  .landing .landing-about-us h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 820px) {
  .landing-about-us .about-us-section--small .small-row__inner {
    padding: 0px 25px;
    top: 10%;
  }
  .landing-about-us .about-us-section--small .small-row__right p {
    font-size: 14px;
    line-height: 22px;
  }
  .landing-about-us .video-wrapper {
    padding: 25px;
  }
  .landing-about-us .inner-box__text {
    padding-top: 50px;
  }
  .landing-about-us .right-adj {
    padding-right: 100px;
    padding-left: 30px;
  }
  .landing-about-us .left-adj {
    padding-left: 60px;
  }
}

@media only screen and (min-width: 1000px) {
  .landing-about-us .about-us-section--small .small-row__inner {
    padding: 0px 35px;
    top: 15%;
  }
  .landing-about-us .about-us-section--small .small-row__right p {
    font-size: 17px;
    line-height: 28px;
  }
  .landing-about-us .inner-box__text {
    padding-top: 80px;
  }
  .landing-about-us .inner-box__text.video-wrapper {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 680px) {
  .landing-about-us .about-us-section {
    display: grid;
  }
  .landing-about-us .about-us-section p {
    font-size: 14px;
  }
  .landing-about-us .about-us-section img {
    margin: 0 auto;
  }
  .landing-about-us .about-us-section__inner {
    margin-top: 40px;
  }
  .landing-about-us .about-us-section.image-right .g-5 {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .landing-about-us .about-us-section .g-5 {
    padding: 0px;
  }
  .landing-about-us .about-us-section--small .small-row__right {
    display: grid;
    align-items: center;
    padding: 25px;
  }
}

.post__title a {
  font-size: 20px;
  color: #000;
}

.post__teaser, .post__body {
  font-size: 16px;
}

.post__body h3 {
  font-size: 20px;
}

.edu-list-item__title a {
  color: #238BA3;
}

.instashop-banner {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
}

.instashop-banner__title {
  color: #000;
  font-family: corundum-text,serif;
  font-weight: bold;
}

.instashop-grid {
  padding: 0 15px;
}

.instashop-grid__item {
  margin-bottom: 15px;
}

@media only screen and (min-width: 420px) {
  .instashop-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 0;
  }
  .instashop-grid__item {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 720px) {
  .instashop-grid {
    gap: 20px;
  }
}

.instashop-link .instashop-link__image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

.samples__toggle {
  background: none;
  background-color: #238BA3;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #015b70;
}

html, body {
  color: #000;
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-size: 2.3em;
}

img {
  max-width: 100%;
  height: auto;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
  max-width: none;
}

.main-content {
  max-width: none;
}

.main-content__inner .pencil {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.page--default .main-content__inner > div,
.page--about-us-default .main-content__inner > div {
  max-width: none;
}

.pencil-banner {
  max-width: none;
}

@media only screen and (min-width: 820px) {
  .main-banner-wrap .banner-feature img {
    max-width: none;
  }
}

.list-link {
  color: #000;
}

.post_title {
  display: none;
}

.instagram-widget {
  max-width: none;
}

.instagram-header-container {
  text-align: center;
  padding: 15px 0 20px;
  background-color: #FAF9F5;
  margin-top: 50px;
}

.instagram-header-container .bar {
  margin-bottom: 0px;
}

.instagram-header-container p {
  margin-top: 0px;
  font-size: 15px;
}

.instagram-header-container a {
  color: #000;
}

.instagram-link-container {
  padding: 60px 0;
  margin: auto;
  text-align: center;
  background-color: #FAF9F5;
}

.home-wrap > br {
  display: none;
}

/* ---------------------------
    #tabs
--------------------------- */
.tabs-nav .tabs-nav--active {
  background: #238BA3;
}
